<template>
  <ActionForm @submit="submit">
    <template v-slot:desc>
      Укажите номер телефона, который Вы использовали при регистрации.
    </template>
    <Phone
      v-model="form.phone"
      :error="formErrors.phone"
      title="Номер телефона"
    />
  </ActionForm>
</template>

<script>
import Validation from "@/ext/validation/Validation";
import validation from "@/mixins/validation";

import sendUnsubscribe from "@/api/sendUnsubscribe";

import Phone from "@/components/form/field/Phone";
import ActionForm from "@/templates/action-form/ActionForm";

export default {
  name: "UnsubscribePage",
  mixins: [validation],
  data() {
    return {
      form: {
        phone: "",
      },
      formRules: {
        phone: [Validation.REQUIRED, Validation.PHONE],
      },
    };
  },
  mounted() {
    const innerScript = document.createElement("script");
    const rtb = document.createElement("div");
    rtb.className = "rtb";
    rtb.style.marginBottom = "40px";
    innerScript.innerHTML = `
            let banner = document.querySelector(".rtb")

            if(banner.parentNode.offsetWidth < 969) {
                banner.innerHTML = '<ins class="dd15595d" data-cp-size="300" id="place_110583102687212110"></ins><ins class="dd15595d" data-cp-size="300" id="place_110583102687212110"></ins>';
            } else {
                banner.innerHTML = '<ins class="dd15595d" data-cp-size="970" id="place_110583102687212110"></ins><ins class="dd15595d" data-cp-size="970" id="place_110583102687212110"></ins>';
            }
       `;

    rtb.appendChild(innerScript);
    document.querySelector(".step-t__content").prepend(rtb);

    const script = document.createElement("script");
    script.src = "https://front.adsfin.net/place/110583102687212110/";
    script.async = true;
    document.head.appendChild(script);
  },
  methods: {
    async submit() {
      this.validate();

      if (!this.isValid) return;

      const info = await sendUnsubscribe(this.form);

      let routeName =
        info.status === "phoneNotFound"
          ? "UnsubscribeInfo"
          : "UnsubscribeMessage";
      routeName = info.status === "sendSMS" ? "UnsubscribeSms" : routeName;

      this.$router.push({
        name: routeName,
        params: {
          info,
          data: this.form,
        },
      });
    },
  },
  components: {
    Phone,
    ActionForm,
  },
};
</script>
